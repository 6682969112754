<template>
  <div class="c-points-wrapper">
    <div class="c-points">
      <span class="c-points__key">
        <div v-if="exercise.joker" class="c-joker">
          <img src="/images/joker.svg" alt="joker">
        </div>
        <span v-else>&nbsp;</span>
      </span>
      <span v-if="exercise.total" class="c-points__value">
        {{ exercise.total }}
      </span>
      <span v-else>&nbsp;</span>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'exercise'
  ],
}
</script>
