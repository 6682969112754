<template>
  <div>place holder</div>
</template>

<script>
export default {
  name: "baseTable",
  props: ['exercises', 'items', 'rankingClass', 'tiny', 'showRank'],
}
</script>

<style scoped>
.c-table {
  border-collapse: collapse;
  border-spacing: 0;
  font-feature-settings: "tnum";
  font-size: 12px;
  /* min-width: 48%; */
  width: 100%;
}

.c-table th,
.c-table td {
  text-align: left;
  padding: 6px 8px;
  vertical-align: top;
}

.c-table--half th,
.c-table--half td {
  width: auto;
}

.c-table th.c-table__first-col,
.c-table td.c-table__first-col {
  padding-left: .64cm;
}

.c-table td.c-table__first-col {
  vertical-align: top;
  text-align: center;
}

.c-table thead tr th {
  text-align: left;
  text-transform: uppercase;
  vertical-align: top;
}

.c-table thead tr th {
  font-family: 'Graphik Regular', Helvetica, Arial, sans-serif;
  font-size: 10px;
  font-weight: normal;
  padding-top: 6px;
  padding-bottom: 10px;
}

.c-table thead tr:nth-last-child(2) {
  border-bottom: 1px solid #c5c2c2;
}

.c-table tr.c-table__name-row td {
  padding-top: 0;
  padding-bottom: 3px;
}

.c-table tbody {
  border-bottom: 1px solid #c5c2c2;
  min-height: 90px;
  height: auto;
  page-break-inside: avoid !important;
}

.c-table tbody:nth-child(even) tr {
  background: #f9f9fa;
}

.c-table::v-deep td.c-exercise,
.c-table th.c-exercise__titles {
  min-width: 60px;
  max-width: 83px;
  vertical-align: top;

  padding-right: 8px;
  padding-left: 0;
}

.c-table::v-deep tr.c-table__name-row-total td.c-exercise {
  vertical-align: bottom;
}

.c-table td.c-exercise__lastcol {
  padding-right: 0.64cm;
  padding-left: 10px;
}

.c-table td.c-exercise__last-col .c-wrapper {
  min-width: calc(60px + 0.6cm);
}

.c-table td.c-table__name-row-result {
  width: 30ch;
  min-width: 14ch;
  text-align: left;
  vertical-align: top;
}

.c-table::v-deep td {
  text-align: right;
  font-size: 11px;
  /* vertical-align: bottom; */
}

.c-table::v-deep .c-status--not-finished {
  text-align: center;
  vertical-align: bottom;

}

.c-table::v-deep .c-status--not-finished .c-points-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.c-table::v-deep .c-status--not-finished .c-icon {
  text-align: center;
  /* margin: 0 auto 16px; */
  max-width: 28px;


  margin-bottom: 12px;
}

.c-table::v-deep .c-status--not-finished .c-icon img {
  width: 100%;
}


.c-table__fixed-x-small-col {
  width: 60px;
  /* border: 1px solid red; */
}

.c-table__fixed-small-col {
  width: 80px;
  /* border: 1px solid blue; */
}

/* Text Related */
.c-table::v-deep .c-total {
  display: inline-block;
  /* margin-top: 4px; */
  font-family: 'Graphik Medium', Helvetica, Arial, sans-serif;
}

.c-exercise::v-deep div.c-joker {
  display: inline-block;
  width: 12px;
  height: 12px;
  position: relative;
  top: -3px;
}

.c-exercise::v-deep .c-points {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.c-text-bold {
  font-family: 'Graphik Medium', Helvetica, Arial, sans-serif;
}

/* Ranking */

::v-deep .c-badge {
  position: relative;
  background: #5d5857;
  color: white;
  display: inline-block;
  width: 2ch;
  height: 2ch;
  border-radius: 50%;
  padding: 4px;
}

::v-deep .c-badge:after {
  content: attr(data-rank);
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2ch;
  height: 2ch;
}

.c-table .u-vertical-bottom {
  vertical-align: bottom;
}

.c-table::v-deep .c-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  min-height: 100%;
  position: relative;
}

.c-exercise::v-deep  .c-points.c-total.c-label {
  display: inline;
  text-align: center;
  text-transform: uppercase;
}

.c-table::v-deep .c-points.c-total.c-total--large {
  font-size: 13px;
}

.u-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 100%;
}

.u-flex::v-deep .c-total {
  align-self: flex-end;
}


/* Auxiliary classes: */
.c-table__empty-last-col {
  width: auto !important;
}

.u-150px {
  width: 150px !important;
}

.u-100px {
  width: 100px !important;
}

.u-90px {
  width: 90px !important;
}

.u-80px {
  width: 80px !important;
}

.u-padding-right-10px {
  padding-right: 10px !important;
}

.u-padding-right-20px {
  padding-right: 20px !important;
}

.u-padding-right-30px {
  padding-right: 30px !important;
}
</style>
