<template>
  <table ref="ranking" class="c-table js-table u-margin-top-medium">
    <thead>
    <tr>
      <th class="c-table__fixed-x-small-col c-table__first-col" v-if="showRank">{{$t('report.rank')}}</th>
      <th class="c-table__name-row-result">{{$t('participant')}}</th>
      <th v-for="exercise in exercises" :key="exercise">
        {{ exercise }}
      </th>
      <th class="c-exercise__last-col">
        <div class="c-table__fixed-small-col">{{ $t('total') }}</div>
      </th>
      <th class="c-table__empty-last-col"></th>
    </tr>
    </thead>
    <tbody v-for="(item, it_i) in items"  :key="'item-'+item.id">
      <tr  :class="{'c-table--odd-row': (it_i % 2) === 0}">
        <td class="c-table__fixed-x-small-col c-table__first-col" v-if="showRank" :rowspan="2 + item.names.length">
          <span class="c-badge" :data-rank="item.ranking"></span>
        </td>
        <td class="c-table__name-row-result">
          <span class="c-text-bold">{{ item.clubName }}<br/></span>
        </td>
        <td v-for="(exercise, ex_i) in item.exercises" :key="item.id + ex_i" class="c-exercise u-100px u-padding-right-20px">
          <div class="c-wrapper js-wrapper">
            <div class="c-points-wrapper">
              <span class="c-text-bold">&nbsp;</span>
            </div>
          </div>
        </td>
        <td class="c-table__fixed-small-col c-exercise c-exercise__last-col">
          <div class="c-wrapper">
            <div class="c-points" v-if="item.annotations && item.annotations.deductions">
              <span class="c-points__key"></span>
              <span class="c-points__value ">P {{ item.annotations.deductions }}</span>
            </div>
          </div>
        </td>
        <td class="c-table__empty-last-col"></td>
      </tr>
      <tr v-for="(name, na_i) in item.names" :key="'name-'+item.id+'-'+name"
          class="c-table__name-row" :class="{'c-table--odd-row': (it_i % 2) === 0}">
        <td class="c-table__name-row-result">
          {{ name }}
        </td>
        <td v-for="(exercise, ex_i) in item.exercises" :key="item.id + ex_i" class="c-exercise u-100px u-padding-right-20px">
          <exerciseResult v-if="exercise.values" :exercise="exercise.values[na_i]"/>
        </td>
        <td class="c-table__fixed-small-col c-exercise c-exercise__last-col" v-if="item.aaResults">
          <div class="c-points-wrapper">
            <div class="c-points">
              <span class="c-points__key">&nbsp;</span>
              <span class="c-points__value ">{{ item.aaResults[na_i] }}</span>
            </div>
          </div>
        </td>
        <td class="c-table__fixed-small-col c-exercise c-exercise__last-col"
            v-if="! item.aaResults && na_i === 0" :rowspan="item.names.length">
          <div class="c-wrapper js-total">
            <div class="c-points-wrapper">
              <template v-if="item.annotations && item.annotations.qualified">
                <span class="c-badge" data-rank="Q"></span>
              </template>
              <template v-if="item.annotations && item.annotations.reserve">
                <span class="c-badge" data-rank="R"></span>
              </template>
              <template v-if="item.award">
                <div class="c-points c-total c-total--large">
                  <span class="c-points__key">&nbsp;</span>
                  <span class="c-points__value">{{ item.award }}</span>
                </div>
              </template>
            </div>
          </div>
        </td>
        <td class="c-table__empty-last-col"></td>
      </tr>
      <tr class="c-table__name-row-total" :class="{'c-table--odd-row': (it_i % 2) === 0}">
        <td class="c-table__name-row-result">
          <span class="c-text-bold">{{ item.bib }}</span>
        </td>
        <td v-for="(exercise, ex_i) in item.exercises" :key="item.id + ex_i" class="c-exercise u-100px u-padding-right-20px">
          <div class="c-points c-total">
            <span class="c-points__key">
              <span v-if="exercise.ranking" class="c-badge" :data-rank="exercise.ranking"></span>
            </span>
            <span class="c-points__value ">{{ exercise.total }}</span>
          </div>
        </td>
        <td class="c-table__fixed-small-col c-exercise c-exercise__last-col">
          <div class="c-points c-total c-total--large">
            <span class="c-points__key">&nbsp;</span>
            <span class="c-points__value ">{{ item.total }}</span>
          </div>
        </td>
        <td class="c-table__empty-last-col"></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import base from './baseTable.vue'

  export default {
    name: 'teamTable',
    extends: base,
    components: {
      'exerciseResult': require('./teamExerciseResult.vue').default,
    },
  }
</script>
