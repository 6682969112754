<template>
  <div class="ranking u-margin-top-medium" v-if="processed">
    <h3 class="c-title c-title--page-section">{{ processed.title }}</h3>
    <component :is="component" :exercises="processed.data.exercises" :items="processed.data.items"
         :rankingClass="processed.data.rankingClass" :tiny="processed.data.tiny" :showRank="showRank()"/>
    </div>
</template>

<script>
  import rankingLib from 'client/lib/ranking.js'
  import rounds from 'client/lib/rounds'

  import find from 'lodash/find'

  export default {
    name: 'ranking',
    components: {
      'single-table': require('./singleTable').default,
      'team-table': require('./teamTable').default,
      'awards-table': require('./awardsTable').default,
    },
    props: ['ranking', 'filter'],
    data: function () {
      return {
        processed: null,
        component: 'single-table',
      }
    },
    watch: {
      ranking: {
        immediate: true,
        handler: function () {
          this.init()
        }
      },
      filter: {
        immediate: false,
        handler: function () {
          this.init()
        }
      }
    },
    methods: {
      init: function () {
        if (this.ranking) {
          const ranking = this.ranking
          const category = find(this.$store.state.categories.items, (item) => item.id === ranking.categoryId)
          const round = rounds.getRound(ranking.roundIndex)
          const discipline = this.$store.state.eventDiscipline.discipline

          this.processed = rankingLib.preparePrint(ranking, category, round, discipline, this.filter)
          this.component = this.getComponent()
        }
      },
      getComponent: function() {
        let component = 'single-table'

        switch (this.processed.type) {
          case 'teamRanking':
            component = 'team-table'
            break
          case 'awards':
            component = 'awards-table'
            break
        }

        return component
      },
      showRank: function () {
        return this.processed.data.settings.showRank
      },
    }
  }
</script>

<style>
</style>
